import React from 'react';
import './noiseMonitoring.css';

const NoiseMonitoring = () => {
    return (
        <div>
            <section className='noiseMonitoring'>
                <div className='noiseMonitoring-heading'>
                    <h1>Noise Monitoring</h1>
                </div>
                <div className='noiseMonitoring-content'>
                    <div className='noiseMonitoring-content-left'>
                        <img src='/images/services/environmentMonitoring/Noise-Monitoring.jpg' alt='' />
                    </div>
                    <div className='noiseMonitoring-content-right'>
                        <p>
                            Environmental Noise, defined as unwanted or harmful outdoor sound created by human activities, can be generated by traffic, industry, construction, and recreation activities.
                        </p>
                        <p>
                            Industrial noise generated from plants, mills, refineries, shipping docks and utilities impact the quality of the surrounding community. By controlling and monitoring the noise, a community and industry can establish a working relationship that will benefit all. An effective noise monitoring program will allow an industrial site to understand the noise sources and time periods that are causing concerns and then develop a plan for mitigation.
                        </p>
                        <p>
                            Ecomen helps you to understand sound and vibration sources within your regulatory and community context. We’re experts at predicting, modeling, measuring and interpreting sound in the environment, and we’re deeply familiar with the complex regulations that govern it. We have the in-depth expertise, judgment and insight to find the most elegant and efficient solution.
                        </p>
                    </div>
                </div>
            </section>

            {/* STUDY */}
            <section className='noiseMonitoring-study'>
                <div className='noiseMonitoring-study-heading'>
                    <p>Ecomen environmental Noise projects include:</p>
                </div>
                <div className='noiseMonitoring-study-list'>
                    <ul>
                        <li>
                            Power Plants
                        </li>
                        <li>
                            Waste Water Treatment Plants
                        </li>
                        <li>
                            Industrial Plants
                        </li>
                        <li>
                            Mining Area
                        </li>
                        <li>
                            Steel Plants
                        </li>
                        <li>
                            Transportation
                        </li>
                        <li>
                            Commercial Facilities
                        </li>
                    </ul>
                </div>
            </section>

            {/* Buttons */}
            <section className='noiseMonitoring-buttons'>
                <div>
                    <a href='/environment-monitoring'>Back</a>
                </div>
                <div>
                    <a href='/contact'>Contact Us</a>
                </div>
            </section>
        </div>
    )
}

export default NoiseMonitoring
